@import './src/styles/lib';

.PageBanner {
  width: 100%;
  height: 600px;
  position: relative;
  margin-bottom: 80px;
  @include mobile {
    width: calc(100% + 30px);
    height: 400px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 40px;
  }
  @include tablet {
    width: calc(100% + 60px);
    height: 500px;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 60px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    z-index: 2;
    h1 {
      font-size: 3.75rem;
      line-height: 4.875rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      text-align: center;
      @include mobile {
        font-size: 28px;
        line-height: 1.3em;
      }
    }
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover !important;
  }
}
