@import 'src/styles/lib';

.ShopPriceRange {
  &__label {
    position: relative;
    width: 100%;
    font-size: 14px;
    line-height: em(20px, 14px);
    color: $black;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    margin-bottom: 15px;

    &_icon {
      position: absolute;
      right: 0;

      &_spinner {
        position: relative;
        top: 2px;
        width: 12px !important;
        height: 12px !important;
        border: 0.2em solid #000000 !important;
        border-right-color: transparent !important;
        vertical-align: unset !important;
      }

      &_arrow {
        transition: 0.3s;
        fill: rgba(0, 0, 0, 1);
        opacity: 0.4;
      }

      &[data-opened='true'] {
        //
      }
    }
  }
}
