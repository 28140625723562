@import './../../styles/_lib.scss';

.ProductSingle {
  height: 100%;
  margin-top: 40px;

  &--simple {
    .ProductSingle__content__variations {
      border: none !important;
      padding: 0 !important;
      margin-bottom: 4px;
      .label {
        color: #000000;
        font-weight: bold;
      }
      span.color,
      span.size {
        line-height: 25px;
        border: none !important;
        cursor: initial;
      }
    }
    .ProductSingle__variations_wrapper {
      margin: 10px 0;
      padding-bottom: 8px;
      border-bottom: 1px solid $border-color;
    }

    .ProductSingleDetails {
      margin-top: 10px;
    }
  }

  & > .container {
    @include mobile {
      padding: 0 !important;
    }
  }

  @include mobile {
    margin-top: 0;
  }

  &__content {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    margin-left: 30px;
    min-height: 500px;
    flex-direction: column;

    @include mobile {
      margin-left: 0;
      min-height: auto;
      padding-top: 20px;
    }

    .top-content {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    &__header {
      border-bottom: 1px $border-color solid;
      padding-bottom: 15px;
    }

    &__title {
      color: $black;
      display: block;
      font-size: 32px;
      line-height: 1.3em;
      font-weight: bold;
      margin-bottom: 0;

      @include mobile {
        font-size: 22px;
        line-height: 24px;
      }
    }

    .ProductPrices {
      display: flex;
      justify-content: flex-end;
      h4 {
        font-size: 26px;
        line-height: 1.5em;
      }
    }

    &__links {
      margin-top: 15px;
      p {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 300;
        cursor: pointer;
        margin-bottom: 5px;
        transition: $transition1;
        &:hover {
          color: #878787;
        }
      }
    }

    &__description {
      margin-bottom: 10px;

      h4 {
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.3em;
        color: darken($gray, 20.5%);
      }

      p {
        font-size: 16px !important;
      }
    }

    &__variations {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 15px;
      border-bottom: 1px $border-color solid;
      padding-bottom: 5px;

      &--options {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__customOptions {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
        .form-group {
          width: calc(50% - 10px);
          margin-bottom: 0 !important;
        }
        label {
          font-weight: 300 !important;
          color: #878787 !important;
          margin-bottom: 5px !important;

          @include mobile {
            font-size: 10px !important;
          }
        }
        input {
          height: 38px !important;
          &::placeholder {
            font-weight: 300 !important;
            color: #878787 !important;
            opacity: 0.5;
          }
        }
      }

      span {
        margin-bottom: 10px;
        display: inline-block;
      }

      span.label {
        width: 30%;
        font-size: 16px;
        font-weight: 300;
        line-height: 1.3em;
        color: darken($gray, 40%);
      }

      span.size {
        color: $black;
        padding: 4px 5px;
        min-width: 32px;
        height: 32px;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.7em;
        text-align: center;
        position: relative;
        border: 1px solid #d8d8d8;
        user-select: none;
        cursor: pointer;
        margin-left: 10px;
        text-transform: uppercase;
        &.disabled {
          pointer-events: none;
          background-color: #d8d8d8;
          &:before {
            content: '';
            position: absolute;
            width: calc(100% + 10px);
            height: 1px;
            transform: rotate(-45deg) translate(-17px, -1px);
            background: red;
          }
        }
        &.isActive {
          border-color: black;
        }
      }

      span.color {
        width: 32px;
        height: 32px;
        cursor: pointer;
        border: 1px solid #d8d8d8;
        position: relative;
        border-radius: 100%;
        margin-left: 10px;
        div {
          width: 80%;
          height: 80%;
          margin-right: 0 !important;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 100%;
        }

        &.disabled {
          pointer-events: none;

          div {
            opacity: 0.5;
          }

          &:before {
            content: '';
            position: absolute;
            width: calc(100% + 10px);
            height: 1px;
            transform: rotate(-45deg) translate(-11px, 4px);
            background: red;
            z-index: 3;
          }
        }

        &.isActive {
          border-color: $black;
        }
      }

      span.transport-status {
        color: #53d800;
        font-size: 14px;
        font-weight: 300;
        display: inline-block;
      }
    }

    &__actions {
      display: flex;
      margin-top: auto;
      padding-top: 20px;

      @include tablet {
        margin-top: 20px !important;
        padding-top: 0;
      }

      @include mobile {
        display: flex;
        padding-top: 25px;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: space-between;
      }

      &--extras {
        display: flex;

        @include mobile {
          width: 100%;
          gap: 10px;
        }
      }

      button.add_to_cart {
        height: 47px;
        color: $white;
        margin: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        font-size: 12px;
        padding: 0 30px;
        max-width: 290px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        background: $black;
        letter-spacing: 0.25em;
        transition: 300ms ease;
        text-transform: uppercase;
        border: 1px solid $black;
        border-radius: 5px;

        @include tablet {
          padding: 0;
          margin: 0 10px;
        }
        @include mobile {
          margin-left: 0;
          padding: 0 10px;
          max-width: none;
          min-width: 180px;
          margin-right: 0;
        }

        // &:hover {
        //   background-color: $white;
        //   color: $black;
        // }

        // &.disabled,
        // &:disabled {
        //   background-color: #d8d8d8;
        // }

        span.cart {
          width: 18px;
          height: 16px;
          margin-right: 10px;
          vertical-align: sub;
          background-size: 100%;
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url('./../../assets/icons/cart-white.svg');
        }
      }

      .Quantity {
        margin: auto 0;
      }

      .WishListButton {
        width: 47px;
        height: 47px;
        margin-top: 0;
        @include mobile {
          margin-left: 0;
          width: 75px;

          .Icon {
            width: 75px;
          }
        }

        button {
          background-color: $gray_light;
        }
      }
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    @include mobile {
      flex-direction: column;
    }
  }
  .Quantity__select {
    select {
      width: 75px;
      @include mobile {
        padding: 11px 12px 12px;
      }
    }
  }
}

.ProductSingleLoader {
  @include tablet {
    width: 100%;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  @include mobile {
    width: 100%;
    height: 100%;
    padding-top: 15px;
    svg {
      width: 100%;
      height: auto;
    }
  }
}
.Whatsapp__button {
  height: 47px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 0;
  border-radius: 5px;

  @include mobile {
    border: 1px solid #212529;
    width: 100%;
    font-size: inherit;
  }
  svg {
    color: #212529;
  }
}
