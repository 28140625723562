@import './../../../../styles/_lib.scss';

.SortSelect {
  position: relative;

  @include desktop {
    max-width: 300px;
  }

  select {
    width: 100%;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 34px;
    color: $black;
    outline: none;
    display: block;
    font-size: 14px;
    padding: 0 35px 0 10px;
    appearance: none;
    font-weight: 600;

    background-size: 20px;
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-image: url('./../../../../assets/icons/chevron-down.svg');
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 13px;

    .Spinner {
      background-color: $gray;
      padding: 3px;

      @include mobile {
        margin-right: 10px;
        padding: 5px;
      }
    }
    .text-secondary {
      color: $black !important;
    }
  }
}
