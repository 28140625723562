@import 'styles/lib';

.App {
  &__backdrop {
    width: 100%;
    height: 100%;
    background: rgba(33, 33, 33, 0.52);
    position: fixed;
    z-index: 10;
    display: none;
  }

  &--loading {
    .LoadingPlaceholder {
      position: fixed;
      z-index: 999999999999;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      justify-content: center;
      align-items: center;
      svg {
        color: $black;
      }
      p {
        font-size: 28px;
        color: $black;
      }
    }
  }

  &--show-backdrop .App__backdrop {
    display: block;
  }
}
