@import "./../../styles/_lib.scss";

.Wishlist {
  margin-top: 55px;

  .WishlistButton {
    button {
    }
  }

  &__list {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 4fr);
    @include tablet {
      grid-gap: 30px;
      grid-template-columns: repeat(3, 4fr);
    }
    @include mobile {
      grid-gap: 15px;
      grid-template-columns: repeat(2, 4fr);
    }
  }
}
