@import './../../../styles/_lib.scss';

.ShippingMethod {
  margin-bottom: 30px;
  &__title {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3em;
    margin-bottom: 10px;
    color: $black;
    text-transform: uppercase;

    span {
      width: 30px;
      height: 30px;
      font-size: 15px;
      border: 2px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
    }
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid $gray;
    padding: 10px 5px;

    label {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;

      @include mobile {
        align-items: start;
      }

      :checked + span.name {
        &:before {
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url('./../../../assets/icons/dot.svg');
          background-size: 8px;
        }
      }
    }
    span.name {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1.3em;
      margin-bottom: 0;
      color: $black;

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: $white;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        border: 1px #000 solid;
      }
    }
    span.price {
      display: block;
      font-size: 16px;
      line-height: 1.3em;
      font-weight: bold;
      color: $black;
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.is--invalid.invalid-feedback {
  display: block;
}
