@import 'src/styles/lib';

.CheckoutConfirmation {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__head {
    text-align: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      .Icon {
        width: 45px;
        height: 45px;

        @include mobile {
          width: 35px;
          height: 35px;
        }
      }
    }

    .success-title {
      font-size: 70px;
      margin-bottom: 50px;

      @include mobile {
        font-size: 28px;
        margin-bottom: 40px;
      }
    }

    .failed-title {
      font-size: 70px;
      margin-bottom: 50px;

      @include mobile {
        font-size: 40px;
        margin-bottom: 40px;
      }
    }

    .success-subtitle {
      font-size: 35px;
      margin-bottom: 60px;

      @include mobile {
        font-size: 22px;
        margin-bottom: 40px;
      }
    }

    .failed-subtitle {
      font-size: 35px;
      margin-bottom: 60px;

      @include mobile {
        font-size: 22px;
        margin-bottom: 40px;
      }
    }

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      p {
        max-width: 700px;
        font-size: 18px;
        margin-bottom: 10px;

        @include mobile {
          max-width: 100%;
          font-size: 13px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
