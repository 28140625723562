@import './../../../../styles/_lib.scss';

.ShopCatalogBanner {
  width: 100%;
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
    @include mobile {
      flex-direction: column;
      gap: 20px;
      margin-top: 0;
    }
    @include tablet {
      gap: 20px;
    }
    &--no-image {
      justify-content: center;
      .ShopCatalogBanner__content {
        width: auto;
      }
    }
  }
  &__image {
    width: 70%;
    height: 400px;
    @include mobile {
      width: 100%;
      height: 450px;
    }
    @include tablet {
      width: 60%;
      height: 350px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    width: 30%;
    text-align: center;
    @include mobile {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
    @include tablet {
      width: 40%;
    }
    h4 {
      font-size: 18px;
      font-weight: 300;
      color: $primary;
      opacity: 0.5;
      margin-bottom: 15px;
    }
    h1 {
      font-size: 28px;
      font-weight: 400;
      color: $primary;
      margin-bottom: 15px;
    }
    p {
      font-size: 16px;
      color: $black;
      margin-bottom: 0;
    }
  }
}
