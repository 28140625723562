@import './../../styles/_lib.scss';

.Alert {
  @include mobile {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @include tablet {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .alert {
    margin-bottom: 0;
    border-radius: unset;
    border: none;
  }

  .container {
    display: flex;
    align-items: center;
    padding-right: 0;
  }

  span.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: $white;
    @include mobile {
      vertical-align: top;
    }
  }

  p {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: super;
    color: $black;
    width: 80%;
    line-height: em(18px, 12px);
    font-size: 12px;
  }

  button.close {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-left: auto;
    opacity: 1;
  }

  .alert-danger {
    background-color: #fff;
    border: 1px solid #dc0a0a;
  }

  .alert-warning {
    background-color: #fff;
    border: 1px solid #cb9f1b;
  }

  .alert-success {
    background-color: #fff;
    border: 1px solid #256938;
  }
}
