@import 'src/styles/lib';

.Home {
  margin-top: -94px; //under header
  .container {
    @include mobile {
      padding: 0;
    }
  }
}
