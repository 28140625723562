@import './../../styles/_lib.scss';

.RelatedProducts {
  margin-top: 90px;

  @include mobile {
    margin-top: 80px;
  }

  &__title {
    color: $black;
    display: block;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 40px;
    @include mobile {
      margin-bottom: 20px;
    }
  }

  &__list {
    display: grid;
    grid-gap: 20px;
    column-gap: 4px;
    grid-template-columns: repeat(4, 4fr);
    margin: 0px -10px;

    // Update image sizes for related products
    .ProductItem {
      &__image {
        .ProductItem__image__holder {
          img {
            height: 500px;
            object-fit: cover;

            @include tablet {
              height: 100%;
            }

            @include mobile {
              height: 100%;
            }

            @include small-mobile {
              height: 300px;
            }
          }
        }
      }
    }

    @include mobile {
      grid-gap: 15px;
      grid-template-columns: repeat(2, 4fr);

      .ProductItem {
        &:first-child {
          grid-row: unset;
          grid-column: unset;
        }
      }
    }

    @include small-mobile {
      grid-gap: 5px;
    }
  }
}

.RelatedProductsLoader {
  @include tablet {
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: auto;
      display: block;
    }
  }
  @include mobile {
    width: 100%;
    height: 100%;
    svg {
      width: 100%;
      height: auto;
    }
  }
}
