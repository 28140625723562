@import 'src/styles/lib';

.ShopLayout {
  width: 100%;
  margin-top: 40px;
  position: relative;

  @include mobile {
    margin-top: 30px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 0px 20px;
    &-right {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    &-grid {
      display: flex;
      align-items: center;
      gap: 5px;
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
      .Icon {
        cursor: pointer;
        opacity: 0.5;
        &.is--active {
          opacity: 1;
        }
      }
    }
    @include mobile {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &--no-products {
    @include desktop {
      .ShopFilterActions {
        margin-bottom: 0;
      }
    }
  }
  &__content {
    &--four {
      .ProductsList {
        @include small-desktop {
          grid-template-columns: repeat(4, 4fr);
        }
        @include desktop {
          grid-template-columns: repeat(4, 4fr);
        }
      }
    }
    &--three {
      .ProductsList {
        @include small-desktop {
          grid-template-columns: repeat(3, 4fr);
        }
        @include desktop {
          grid-template-columns: repeat(3, 4fr);
        }
      }
      .ProductItem__image__holder {
        @include small-desktop {
          height: 440px;
        }
        @include desktop {
          height: 440px;
        }
      }
    }
  }
}
