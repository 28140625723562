@import './../../../styles/_lib.scss';

.ShopCategories {
  margin-bottom: 30px;
  a {
    transition: none !important;
  }

  &__title {
    width: 100%;
    color: $black;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42857em;
    margin-bottom: 15px;
    text-align: left;
    text-transform: uppercase;
  }

  &__category {
    display: block;
    position: relative;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.07143em;
    color: $black;
    margin-bottom: 15px;
    text-transform: capitalize;
    cursor: pointer;

    &:hover {
      color: #000;
    }

    &[data-active='true'] {
      font-weight: 500;
    }
  }
}
