@import 'src/styles/lib';

.SearchResults {
  width: 100%;
  margin-top: 50px;

  &__title {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 50px;

    text-align: center;
  }

  &__term {
    font-weight: 500;
    display: block;
  }
}
