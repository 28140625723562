@import './src/styles/lib';

.Profile {
  width: 100%;
  margin-top: 40px;
  display: flex;
  gap: 40px;
  @include mobile {
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }
  .BlockButton {
    margin-top: 20px;
  }
}
