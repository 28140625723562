@import './../../../styles/_lib.scss';

.ProductSingleSlider {
  width: 660px;
  // overflow: hidden;
  position: relative;
  min-height: 100%;
  max-height: 580px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  @include tablet {
    width: 450px;
  }
  @include mobile {
    width: 100%;
    max-height: unset;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  &__gallery {
    // width: calc(100% - 80px);
    width: 100%;
    height: 500px;
    overflow: hidden;
    @include mobile {
      width: 100%;
      height: calc(100vh - 140px);
      margin-bottom: 10px;
    }
  }
  &__counter {
    position: absolute;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    color: #000;
    @include mobile {
      bottom: -40px;
    }
  }
  &__item {
    height: 500px;
    @include mobile {
      height: unset;
    }
    @include tablet {
      height: unset;
    }

    &--zoomable {
      cursor: zoom-in;
    }
    img {
      object-fit: contain;
      // max-height: calc(100vh - 140px);
      max-height: 100%;
      @include mobile {
        height: calc(100vh - 140px) !important;
        object-fit: cover;
      }
    }
  }
  &--nostock {
    width: calc(100% - 80px);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    @include mobile {
      width: 100%;
      height: 500px;
    }
    span {
      font-size: 16px;
      color: #fff;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    &:before {
      font-size: 0;
      width: 30px;
      height: 30px;
      display: flex;
      background-size: cover;
    }
  }
  .slick-next {
    right: 0;
    &:before {
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBVcGxvYWRlZCB0bzogU1ZHIFJlcG8sIHd3dy5zdmdyZXBvLmNvbSwgR2VuZXJhdG9yOiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4NCjxzdmcgZmlsbD0iIzAwMDAwMCIgaGVpZ2h0PSI4MDBweCIgd2lkdGg9IjgwMHB4IiB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiANCgkgdmlld0JveD0iMCAwIDMzMCAzMzAiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggaWQ9IlhNTElEXzIyMl8iIGQ9Ik0yNTAuNjA2LDE1NC4zODlsLTE1MC0xNDkuOTk2Yy01Ljg1Ny01Ljg1OC0xNS4zNTUtNS44NTgtMjEuMjEzLDAuMDAxDQoJYy01Ljg1Nyw1Ljg1OC01Ljg1NywxNS4zNTUsMC4wMDEsMjEuMjEzbDEzOS4zOTMsMTM5LjM5TDc5LjM5MywzMDQuMzk0Yy01Ljg1Nyw1Ljg1OC01Ljg1NywxNS4zNTUsMC4wMDEsMjEuMjEzDQoJQzgyLjMyMiwzMjguNTM2LDg2LjE2MSwzMzAsOTAsMzMwczcuNjc4LTEuNDY0LDEwLjYwNy00LjM5NGwxNDkuOTk5LTE1MC4wMDRjMi44MTQtMi44MTMsNC4zOTQtNi42MjgsNC4zOTQtMTAuNjA2DQoJQzI1NSwxNjEuMDE4LDI1My40MiwxNTcuMjAyLDI1MC42MDYsMTU0LjM4OXoiLz4NCjwvc3ZnPg==);
    }
  }
  .slick-prev {
    left: 0;
    &:before {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjgwMCIgdmlld0JveD0iMCAwIDgwMCA4MDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yNTBfMjkpIj4KPHBhdGggZD0iTTE5Mi40NyA0MjUuNzIzTDU1Ni4xMDcgNzg5LjM1QzU3MC4zMDUgODAzLjU1MSA1OTMuMzMxIDgwMy41NTEgNjA3LjUzMiA3ODkuMzQ4QzYyMS43MzEgNzc1LjE0NyA2MjEuNzMxIDc1Mi4xMjQgNjA3LjUzIDczNy45MjJMMjY5LjYwNyA0MDAuMDA3TDYwNy41MzIgNjIuMDc1MUM2MjEuNzMxIDQ3Ljg3MzkgNjIxLjczMSAyNC44NTA5IDYwNy41MyAxMC42NDk3QzYwMC40MzIgMy41NDkwNSA1OTEuMTI1IC01Ljk0OTkxZS0wNSA1ODEuODE4IC02LjAzMTI3ZS0wNUM1NzIuNTEyIC02LjExMjYzZS0wNSA1NjMuMjA1IDMuNTQ5MDYgNTU2LjEwNCAxMC42NTIxTDE5Mi40NyAzNzQuMjk4QzE4NS42NDggMzgxLjExNyAxODEuODE4IDM5MC4zNjYgMTgxLjgxOCA0MDAuMDFDMTgxLjgxOCA0MDkuNjUzIDE4NS42NDggNDE4LjkwNCAxOTIuNDcgNDI1LjcyM1oiIGZpbGw9ImJsYWNrIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjUwXzI5Ij4KPHJlY3Qgd2lkdGg9IjgwMCIgaGVpZ2h0PSI4MDAiIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4MDAgODAwKSByb3RhdGUoLTE4MCkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
    }
  }

  .slick-slide,
  .slick-track {
    :focus {
      outline: none !important;
    }

    outline: none !important;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  &__thumbs {
    // position: static !important;
    width: 70px;
    z-index: 3;
    @include mobile {
      width: 100%;
      height: 70px;
    }

    // .slick-list {
    //   height: 320px;
    //   overflow: hidden;
    // }
    .slick-track {
      float: left;
    }

    &__item {
      border: 1px solid $gray;
      margin-right: 10px !important;
      width: auto !important;
      width: 70px !important;
      height: 70px !important;
      position: relative;
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: 0.65);
        transition: all ease-in-out 0.3s;
        opacity: 0;
      }
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .slick-current {
      .ProductSingleSlider__thumbs__item {
        &:before {
          opacity: 0.9;
        }
      }
    }
  }
}

.ProductSingleSliderLoader {
  @include tablet {
    width: 100%;
    height: auto;
    svg {
      width: 100%;
      height: auto;
      display: Block;
    }
  }
  @include mobile {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.GallerySlider {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  padding: 10vh 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  &__close {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.95);
    &-btn {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
  &__image {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    & > div {
      text-align: center;
      background-color: #e1e1e1;
      img {
        display: unset;
        width: auto;
        height: calc(100vh - 140px);
        cursor: zoom-in;
        @include mobile {
          max-width: 100%;
          height: auto;
        }
        @include tablet {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
