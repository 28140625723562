@import './../../styles/_lib.scss';

.Checkout {
  margin-top: 50px;
  @include mobile {
    margin-top: 20px;
  }

  &__address-actions {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    @include mobile {
      flex-wrap: wrap;
    }
    @include tablet {
      flex-wrap: wrap;
    }

    &__title {
      display: flex;
      justify-items: center;
      align-items: center;
      gap: 10px;
      color: $black;
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 1.3em;
      text-transform: uppercase;

      span {
        width: 30px;
        height: 30px;
        font-size: 15px;
        border: 2px solid black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
      }

      &--delivery {
        @include mobile {
          display: none !important;
        }
      }
    }

    &__delivery {
      height: 30px;

      align-items: center;
      font-size: 12px;
      line-height: 1.3em;
      color: $black;
      margin-bottom: 0;

      background-color: $white;
      &.is--active {
        &:before {
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url('./../../assets/icons/dot.svg');
          background-size: 8px;
        }
      }
      &:before {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: $white;
        margin-right: 10px;
        display: inline-block;
        vertical-align: middle;
        border: 1px $border-color solid;
      }
    }

    &__new-delivery {
      color: $black;
      display: block;
      font-size: 12px;
      padding-left: 17px;

      @include mobile {
        width: 45%;
        flex-grow: 0;
        font-size: 11px;
        padding-left: 10px;
      }
      @include tablet {
        flex-grow: 0;
      }
    }
  }

  .agreement {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
    @include mobile {
      height: auto;
      margin-bottom: 20px !important;
    }

    label {
      font-size: 12px;
      color: lighten($black, 46.5%) !important;

      @include mobile {
        margin-left: 0;
      }

      a {
        color: $black;
        text-decoration: underline;
      }

      input {
        margin-top: 3px;
      }
    }
  }
}
