@import 'src/styles/lib';

.ShopSelect {
  position: relative;
  &__label {
    width: 100%;
    font-size: 14px;
    line-height: em(20px, 14px);
    color: $black;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    margin-bottom: 15px;

    &_icon {
      position: absolute;
      right: 0;

      &_spinner {
        position: relative;
        top: 2px;
        width: 12px !important;
        height: 12px !important;
        border: 0.2em solid #000000 !important;
        border-right-color: transparent !important;
        vertical-align: unset !important;
      }

      &_arrow {
        transition: 0.3s;
        fill: rgba(0, 0, 0, 1);
        opacity: 0.4;
      }

      &[data-opened='true'] {
        .ShopSelect__label_icon_arrow {
          fill: #000000;
          transform: rotate(180deg);
        }
      }
    }
  }

  &__options {
    width: 100%;
    max-height: 210px;
    z-index: 1;
    overflow-y: auto;
    @include customScrollbar(3px);

    &::-webkit-scrollbar-track {
      margin-bottom: 10px;
      margin-top: 10px;
      right: 10px;
      padding-right: 10px;
    }
    //List Default
    &_list_item {
      position: relative;
      width: 100%;
      min-height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      cursor: pointer;

      font-size: 15px;
      font-weight: 300;
      line-height: em(15px, 14px);
      color: $black;

      padding-left: 30px;

      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;

        width: 20px;
        height: 20px;
        border: 1px solid $black;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;

        width: 20px;
        height: 20px;
        background-image: url('../../../../assets/icons/check.svg');
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0;
        transition: $transition1;
      }

      &[data-selected='true'] {
        .ShopSelect__options_list_item_check {
          display: block;
        }
        small {
          font-weight: 800;
        }
        &:after {
          opacity: 1;
        }
      }

      &_check {
        display: none;
        position: absolute;
        right: 10px;
      }
    }
    //List Color
    &_color {
      display: flex;
      gap: 9px;
      flex-wrap: wrap;
      > div[data-selected='true'] {
        .ShopAttributes__color {
          border-color: $black;
        }
      }
    }
    //List Size
    &_size {
      display: flex;
      gap: 9px;
      flex-wrap: wrap;
      > div[data-selected='true'] {
        .ShopAttributes__size {
          border-color: $black;
        }
      }
    }
  }
}
