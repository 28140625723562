@import './../../../styles/_lib.scss';

.ProductSingleModal {
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 0 49px 3px #08080847;
  transition: 0.2s ease-in-out;
  transform: translateX(100%);
  z-index: 100;

  @include mobile {
    width: 90%;
  }

  &--opened {
    visibility: visible;
    transform: translateX(0);
  }
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid $gray;
    p {
      margin: 0;
      font-size: 20px;
    }
    .Icon {
      width: 20px;
      height: 20px;
    }
  }

  &-body {
    height: calc(100% - 71px);
    overflow: auto;
    padding: 20px;
  }
  .DefaultPage__content {
    table {
      padding-bottom: 24px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    table::-webkit-scrollbar {
      display: none;
    }
    table thead tr {
      border-bottom: 1px solid $black;
    }
    table thead td {
      font-weight: 700;
    }
    table tbody tr td:first-child {
      font-weight: 700;
    }
    table tr td:first-child {
      min-width: 160px;
      position: sticky;
      left: 0;
      background: #f8f8f7;
      border-right: none;
    }
    table tr td:first-child::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      border-right: 1px solid $black;
    }
    table td {
      padding: 24px 12px;
      min-width: 70px;
      text-align: center;
      vertical-align: middle;
      border: 1px solid #dddcdb;
    }
  }
}
