$font-family-base: 'Manrope';

$primary: #000;
$secondary: #939fb6;

$white: #ffffff;
$black: #212529;
$gray: #ededed;
$gray_light: #f8f8f8;

$border-color: #dddddd;

$button-spacing: 0.25em;
$title-spacing: 0.15em;

$input-radius: 15px;

$mt-heading: 83px;

$transition1: all 0.3s ease-in-out;
$transition2: all 0.5s ease-in-out;
