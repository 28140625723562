@import 'src/styles/lib';

.Button {
  font-weight: 700 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 0 !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0.05em;
  padding: 10px 30px !important;
  color: $white !important;
  background-color: $black !important;
  border-color: $black !important;
  border-radius: 5px !important;
  // &:hover {
  //   color: $black !important;
  //   background-color: $white !important;
  // }

  &--secondary {
    color: $black !important;
    background-color: $white !important;
    border-color: $black !important;
    // &:hover {
    //   color: $white !important;
    //   background-color: $black !important;
    // }
  }
}
