@import './../../../../styles/_lib.scss';

.ShopFilterButton {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 600;
    color: $black;
    margin-bottom: 0;
  }
  .Icon {
    width: 18px;
    height: 18px;
  }
  &__count {
    font-size: 12px;
    font-weight: 600;
    line-height: em(21px, 14px);
    color: #fff;

    position: absolute;
    top: 0;
    right: -27px;
    transform: translate(-50%, -50%);
    background: $black;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile {
      top: 0;
      right: -20px;
    }
  }
}
