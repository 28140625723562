.Page404 {
  text-align: center;
  margin-top: 40px;
  .HeadingTitle h1 {
    margin-bottom: 20px;
  }
  .DefaultButton {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
