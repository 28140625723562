@import './../../../styles/_lib.scss';

.OrderDetails {
  &__table {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;

    @include mobile {
      margin-bottom: 15px;
    }

    &__title {
      display: block;
      font-size: 14px;
      text-align: left;
      font-weight: 700;
      line-height: 1.3em;
      padding-left: 0;
      margin-bottom: 10px;
      color: $black;
      text-transform: uppercase;
    }
    .CartSidebar-list-item {
      margin-bottom: 20px;
    }

    &__thead {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px #dddddd solid;
      padding-bottom: 10px;
      > strong {
        width: 33.333%;
      }
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px #dddddd solid;
      padding: 10px 0px;
      > span {
        width: 33.333%;
      }
      &__btn {
        min-width: 68px;
        background-color: $black !important;
        border-radius: 0 !important;
        border: 1px solid $black !important;
        color: $white !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        opacity: 1 !important;
        &:focus,
        &:active,
        &:hover {
          box-shadow: none !important;
          background-color: $white !important;
          color: $black !important;
        }
        &.disabled {
          background-color: #d8d8d8 !important;
          color: #000 !important;
        }
        @include mobile {
          top: 19px;
        }
      }
    }
  }
  &__total {
    margin: 20px 0;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 4fr);

    @include mobile {
      grid-gap: 15px;
      grid-template-columns: repeat(1, 4fr);
    }
    @include tablet {
      grid-gap: 15px;
      grid-template-columns: repeat(1, 4fr);
    }

    &__price {
      height: 44px;
      display: flex;
      padding: 0 20px;
      background: #fff;
      border: 1px solid $primary;
      align-items: center;

      strong {
        color: $black;
        display: block;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        &:last-child {
          font-size: 18px;
          margin-left: auto;
          text-transform: capitalize;
        }
      }

      span {
        color: $black;
        display: block;
        font-size: 18px;
        line-height: 1.3em;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        &:last-child {
          font-size: 18px;
          margin-left: auto;
          text-transform: capitalize;
        }
      }
    }
  }
}
