@import './src/styles/lib';

.Register {
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;

  &__wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background: rgb(140 140 140 / 10%);
    border-radius: 20px;
    padding: 75px 50px;

    @include mobile {
      padding: 40px 20px;
    }

    h4 {
      margin-bottom: 20px;
    }
    &-text {
      display: inline-block;
      margin: 0;
      font-weight: 500;
    }
  }

  .form-group {
    .form-control {
      padding: 0 15px !important;
      border-radius: 5px !important;
    }
  }

  &__submit {
    width: 100%;
    height: 44px;
  }
}
