@import './../../../../styles/lib';

.ShopSidebar {
  width: 400px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0 0 49px 3px #08080847;
  transition: 0.2s ease-in-out;
  transform: translateX(-100%);
  z-index: 100;
  @include mobile {
    width: 90%;
  }

  &--opened {
    visibility: visible;
    transform: translateX(0);
  }

  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid $gray;
    p {
      margin: 0;
      font-size: 20px;
    }
    .Icon {
      width: 20px;
      height: 20px;
    }
  }

  &-body {
    height: calc(100% - 88px);
    overflow: auto;
    padding: 20px;
  }

  &__product_type {
    margin-bottom: 30px;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 20px;
  }
}
