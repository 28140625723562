@import './../../styles/_lib.scss';

.ProductsList {
  display: grid;
  grid-gap: 20px;
  column-gap: 4px;
  grid-template-columns: repeat(4, 4fr);

  &--empty {
    grid-template-columns: repeat(1, 4fr) !important;
  }
  @include mobile {
    grid-gap: 28px;
    column-gap: 2px;
    grid-template-columns: repeat(2, 4fr);
  }
  @include tablet {
    grid-template-columns: repeat(2, 4fr);
  }
  &__loading {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}

// Updates image sizes only on Shop and Bridal pages
.ShopByCategory__products {
  .ProductItem {
    &__image {
      &__holder {
        height: 700px;
        img {
          object-fit: cover;
        }

        @include tablet {
          max-height: 500px;
        }

        @include mobile {
          max-height: 500px;
        }

        @include small-mobile {
          max-height: 300px;
        }
      }
    }
  }
}

.ProductItem {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  &__Loader {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:first-child {
    &__image {
      &__holder {
        max-height: initial;
      }
    }
  }

  &__image {
    width: 100%;
    display: block;
    position: relative;

    &__holder {
      overflow: hidden;
      width: 100%;
      display: block;
      a {
        width: 100%;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }

      @include tablet {
        max-height: 440px;
      }

      @include mobile {
        max-height: 300px;
      }
    }
  }

  .WishListButton {
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: 0;
    margin-right: 0;

    @include mobile {
      top: 5px;
      right: 5px;
    }

    button {
      background-color: $gray_light;
    }
  }

  &__content {
    display: flex;
    padding: 10px 0 0;
    flex-wrap: wrap;
    text-align: left;
    height: 100%;
    &--loader {
      justify-content: center;
    }

    a {
      width: 100%;
      display: block;
      @include mobile {
        width: 100%;
      }
    }

    &__title {
      color: $black;
      display: block;
      font-size: 12px;
      line-height: 1.4em;
      font-weight: 400;
      margin-bottom: 8px;
      padding-left: 8px;
    }

    .ProductPrices {
      width: 100%;
      padding-left: 8px;
      display: flex;
      align-items: flex-end;
    }
  }

  &--big {
    grid-row: span 2;
    grid-column: span 2;

    .ProductItem__image {
      flex: 1 1 auto;
      max-height: 509px;

      &__holder {
        max-height: 509px;

        @include mobile {
          max-height: 280px;
        }
        @include tablet {
          max-height: 380px;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .ProductItem__content {
      text-align: left;
      a {
        width: 90%;
      }
    }

    @include mobile {
      min-height: 400px;
    }
  }
}
