@import './../../../styles/_lib.scss';

.WishListButton {
  width: 44px;
  height: 44px;
  padding: 0 10px !important;
  margin: 4px auto 0;
  text-align: center;

  border: 0 !important;
  outline: 0 !important;
  background: none !important;
  right: 0px !important;
  top: 0px !important;

  &:active {
    box-shadow: none !important;
    outline: 0 !important;
  }
  &:hover {
    background: none !important;
    .Icon:not(.WishListButton--fill) {
      svg {
        fill: $black;
      }
    }
  }

  .Icon {
    display: flex !important;
    position: relative;
    top: 1px;

    svg {
      fill: $black;
    }
  }

  &--fill {
    svg {
      fill: $black;
    }
  }
}
