@import '../../styles/lib';

.ProfileSidebar {
  width: 100%;
  max-width: 200px;
  @include mobile {
    max-width: 100%;
    margin-bottom: 20px;
  }
  &__tab {
    &:first-child {
      a {
        border-top: 1px solid $border-color;
      }
    }
    span,
    a {
      display: block;
      font-size: 16px;
      text-transform: uppercase;
      border-bottom: 1px solid $border-color;
      padding: 10px;
      transition: $transition1;
      cursor: pointer;
      &:hover {
        background-color: #f4f4f4;
      }
    }
    &--active {
      a {
        background-color: #f4f4f4;
      }
    }
  }
}
