@import './../../styles/_lib.scss';

.Contact {
  &--with-banner {
    margin-top: -94px;
  }
  .DefaultPage__content {
    margin-bottom: 40px;
  }
  &__map {
    width: 100%;
    height: 450px;
    margin-top: 60px;
    @include mobile {
      width: calc(100% + 30px);
      height: 300px;
      margin-left: -15px;
      margin-right: -15px;
    }
    @include tablet {
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
    }
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &__btn_submit {
    width: 240px !important;
    max-width: 240px !important;
    margin-top: 30px;
    @include mobile {
      width: 100% !important;
      max-width: unset !important;
    }
  }
}

.ContactForm {
  width: 100%;
  margin-top: 20px;
  .form-control {
    border: none !important;
    border-bottom: 1px solid $black !important;
    border-radius: unset;
  }
  .form-control:focus {
    color: $black;
    background-color: transparent !important;
    border-color: $black !important;
  }
  .form-group {
    margin-bottom: 30px !important;
    .form-control {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-color: $black !important;
    }
    textarea.form-control {
      height: 80px !important;
    }
    label {
      margin-bottom: 0;
    }
  }

  .form-control-custom {
    height: 50px;
    resize: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .form-select {
    padding-left: 0;
    padding-right: 0;
  }
}
