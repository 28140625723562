@import './../../../styles/_lib.scss';

.PaymentMethod {
  @include mobile {
    margin-bottom: 30px;
  }
  &__title {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3em;
    color: $black;
    margin-bottom: 10px;
    text-transform: uppercase;

    span {
      width: 30px;
      height: 30px;
      font-size: 15px;
      border: 2px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;
    }
  }

  &__item {
    width: 100%;
    border-bottom: 1px solid $gray;
    padding: 10px 5px;

    label {
      width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      margin-bottom: 0;

      @include mobile {
        align-items: start;
        flex-direction: column;
      }

      span.name {
        display: flex;
        align-items: center;
        color: $black;
        font-size: 12px;
        line-height: 1.3em;
        margin-bottom: 0;

        &:before {
          content: '';
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: $white;
          margin-right: 10px;
          display: inline-block;
          vertical-align: middle;
          border: 1px #000 solid;
        }
      }

      :checked + span.name {
        &:before {
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url('./../../../assets/icons/dot.svg');
          background-size: 8px;
        }
      }
    }

    span.cc-icon {
      width: 137px;
      height: 27px;
      display: block;
      margin-left: auto;
      background-repeat: no-repeat;

      @include mobile {
        margin-top: 10px;
        margin-left: 35px;
      }
    }

    span.other_payment-icon {
      width: 48px;
      height: 23px;
      display: block;
      margin-left: auto;
      background-repeat: no-repeat;

      @include mobile {
        margin-top: 10px;
        margin-left: 35px;
      }
    }

    span.cod-icon {
      width: 46px;
      height: 46px;
      display: block;
      margin-left: auto;
      background-repeat: no-repeat;

      @include mobile {
        margin-top: 10px;
        margin-left: 35px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
