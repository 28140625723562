@import './../../../styles/_lib.scss';

.RemoveItemButton {
  color: $black;
  display: block;
  font-size: 14px;
  font-weight: 500;
  background: none;
  margin-left: auto;
  margin-bottom: 10px;

  &:hover {
    fill: $primary;
  }

  @include mobile {
    font-size: 12px;
    margin-bottom: 5px;
  }

  &__icon {
    margin-left: 5px;
    @include mobile {
      svg {
        width: 14px;
      }
    }
  }
}
