@import './../../styles/_lib.scss';

.Cart {
  position: relative;

  &.mt-heading {
    margin-top: 55px !important;
  }
}

.CartTotal {
  width: 100%;
  display: flex;
  align-items: center;
  @include mobile {
    flex-direction: column;
  }

  &__Price {
    display: inline-block;
    font-size: 32px;
    color: $black;
    align-items: center;
    @include mobile {
      text-align: center;
    }

    strong {
      padding-left: 5px;
    }

    span {
      display: block;
      margin-top: -14px;
      margin-bottom: -8px;
      font-size: 14px;
      line-height: 35px;
      color: lighten($black, 46.5%);
      @include mobile {
        display: block;
        line-height: 20px;
        margin-top: -5px;
        margin-bottom: 18px;
      }
    }
  }

  .DefaultButton {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-left: 5px;

    @include mobile {
      padding-left: 0px;
    }

    a {
      width: 100%;
      background: $primary;
      transition: all 400ms ease-in-out;
      &:hover {
        background: $secondary;
      }
    }

    button {
      width: 100%;
    }
  }
}
