@import "./src/styles/lib";

.ThemeContainer {
  margin: 0 auto;
  max-width: 850px;
  width: 100%;

  @include mobile {
    width: 100%;
    padding: 0;
  }

  @include tablet {
    width: 100%;
    padding: 0;
    max-width: none;
    margin: 0;
  }
}
