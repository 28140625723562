@import './../../styles/_lib.scss';

.BookAppointment {
  &--with-banner {
    margin-top: -94px;
  }
  .DefaultPage__content {
    margin-bottom: 40px;
  }
  &__btn_submit {
    width: 240px !important;
    max-width: 240px !important;
    margin-top: 30px;
    @include mobile {
      width: 100% !important;
      max-width: unset !important;
    }
  }
}
.BookAppointmentForm {
  width: 100%;
  margin-top: 20px;
  .form-control {
    border: none !important;
    border-bottom: 1px solid $black !important;
    border-radius: unset;
  }
  .form-control:focus {
    color: $black;
    background-color: transparent !important;
    border-color: $black !important;
  }
  .form-group {
    margin-bottom: 30px !important;
    .form-control {
      padding-left: 0 !important;
      padding-right: 0 !important;
      border-color: $black !important;
    }
    textarea.form-control {
      height: 80px !important;
    }
    label {
      margin-bottom: 0;
    }
  }

  .form-control-custom {
    height: 50px;
    resize: none;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .form-select {
    padding-left: 0;
    padding-right: 0;
  }
  .form-radio {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0 !important;
      position: relative;
      display: flex !important;
      align-items: center;
      gap: 10px;
      font-size: 16px !important;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid $black;
        border-radius: 50%;
        transition: $transition1;
      }
    }
    input[type='radio']:checked + label {
      &:before {
        background-color: $black;
      }
    }
    input {
      display: none !important;
    }
  }
}
