@import './../../styles/_lib.scss';

.CustomDress {
  margin-top: 30px;

  @include mobile {
    margin-top: 0px;
  }

  &--loading {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--title {
    @include mobile {
      text-align: center;
    }

    h1 {
      color: #212529;
      font-size: 32px;
      position: relative;
      margin-bottom: 30px;
      display: inline-block;

      @include mobile {
        font-size: 22px;
        margin-bottom: 15px;
        line-height: 35px;
      }
    }
  }

  /* Step Container */
  &--options,
  &--references,
  &--contacts {
    margin-bottom: 10px;

    &--head {
      background: #fff;
      padding: 30px 20px;
      cursor: pointer;

      @include mobile {
        padding: 20px 15px;
      }

      &.disable {
        background: #e4e4e4;
        pointer-events: none;
      }

      &.completed {
        background: #1f6e06;

        .subtitle {
          color: #fff;
          span {
            &.icon {
              filter: invert(1);
            }
          }
        }
      }
    }

    &--body {
      background-color: #e9ecef36;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition:
        max-height 0.3s ease,
        opacity 0.3s ease;

      &.open {
        max-height: fit-content;
        opacity: 1;
      }
    }

    &.disable {
      opacity: 1.5;
      pointer-events: none;
    }
  }

  .subtitle {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 15px;
    color: #212529;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1.3em;
    text-transform: uppercase;

    span {
      width: 30px;
      height: 30px;
      font-size: 18px;
      border: 2px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 25px;

      &.icon {
        width: 30px;
        height: 30px;
        background-image: url('../../assets/icons/check.svg');
        background-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  button {
    border-radius: 5px;
    background-color: $black;
    color: #fff;
    font-size: 14px !important;
    letter-spacing: 0.05rem;
    padding: 12px 25px;
    text-transform: uppercase;
  }
}

.OptionsForm {
  position: relative;
  padding: 20px;

  @include mobile {
    padding: 20px 10px;
  }

  .form-group {
    .form-color {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      margin-top: 10px;

      &--label {
        display: inline-block;
        cursor: pointer;

        .form-control {
          display: none;
        }

        .form-content {
          display: block;
          width: 32px;
          height: 32px;
          position: relative;
          border: 1px solid;
          border-radius: 100%;
          cursor: pointer;

          div {
            width: 80%;
            height: 80%;
            margin-right: 0;
            position: absolute;
            border-radius: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .form-size {
      display: flex;
      gap: 8px;
      margin-top: 10px;

      &--label {
        position: relative;
        display: block;
        min-width: 32px;
        height: 32px;
        color: #212529;
        font-size: 14px;
        font-weight: 300;
        line-height: 1.7em;
        text-align: center;
        padding: 4px 6px;
        border: 1px solid #ededed;
        text-transform: uppercase;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;

        .form-control {
          display: none;
        }
      }
    }

    .form-checkbox {
      &--label {
        .form-control {
          display: none;
        }

        //List Default
        &_list_item {
          position: relative;
          width: 100%;
          min-height: 33px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          cursor: pointer;

          font-size: 14px;
          font-weight: 300;
          line-height: em(15px, 14px);
          color: $black;

          padding-left: 30px;

          &:last-child {
            margin-bottom: 0;
          }
          &:before {
            content: '';
            position: absolute;
            left: 0;

            width: 20px;
            height: 20px;
            border: 1px solid #ededed;
          }
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 6px;

            width: 20px;
            height: 20px;
            background-image: url('../../assets/icons/check.svg');
            background-size: cover;
            background-repeat: no-repeat;
            opacity: 0;
            transition: $transition1;
          }

          &[data-selected='true'] {
            &:after {
              opacity: 1;
            }
          }
        }
      }
    }

    .textarea-input {
      @include mobile {
        padding: 15px !important;
      }
    }

    .invalid {
      font-size: 13px;
      color: red;
    }
  }

  .form-attribute {
    margin-top: -15px;
    margin-bottom: 10px;

    @include mobile {
      margin-top: -10px;
      margin-bottom: 5px;
    }

    .attribute-label {
      font-size: 13px;

      @include small-mobile {
        font-size: 10px;
      }
    }

    .attribute-control {
      border-radius: 0px !important;
    }
  }
}

.ReferencesForm {
  padding: 20px;

  @include mobile {
    padding: 20px 10px;
  }

  .form-group {
    .form-photo {
      &--label {
        width: 100%;
        height: 400px;
        border: 2px dashed #ededed;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;

        &--image-preview {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }

        &--upload-placeholder {
          text-align: center;
          color: #000;

          img {
            width: 40px;
            height: 40px;
            margin-bottom: 8px;
          }

          svg {
            width: 35px;
            height: 35px;
          }

          p {
            font-size: 14px;
            color: $black;
            margin-top: 10px;
          }
        }
      }

      .form-control {
        display: none;
      }
    }

    .textarea-input {
      height: 400px !important;

      @include mobile {
        padding: 15px !important;
      }
    }

    .invalid {
      font-size: 14px;
      color: red;
    }
  }
}

.ContactsForm {
  padding: 20px;
  margin-top: 0px;

  @include mobile {
    padding: 20px 10px;
  }

  .form-group {
    margin-bottom: 15px !important;

    .form-control {
      border: 1px solid #ededed !important;
      padding: 5px 20px !important;
    }
  }
}
