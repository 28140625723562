@import '../../../styles/_lib';

.PriceRangeSlider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 50px;
  &__slider {
    position: relative;
    width: 100%;
  }

  &__slider__track,
  &__slider__range,
  &__slider__left-value,
  &__slider__right-value {
    position: absolute;
  }

  &__slider__track,
  &__slider__range {
    border-radius: 3px;
    height: 2px;
  }

  &__slider__track {
    background-color: #d9d9d9;
    width: 100%;
    z-index: 1;
  }

  &__slider__range {
    background-color: #000;
    z-index: 2;
  }

  &__slider__left-value,
  &__slider__right-value {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
  }

  &__slider__left-value {
    left: 6px;
  }

  &__slider__right-value {
    right: -4px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    background-color: red;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }

  .thumb--left {
    z-index: 3;
  }

  .thumb--right {
    z-index: 4;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    height: 22px;
    width: 22px;
    margin-top: 1px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #000;
    cursor: pointer;
    height: 22px;
    width: 22px;
    margin-top: 1px;
    pointer-events: all;
    position: relative;
  }
}
